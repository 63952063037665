// extracted by mini-css-extract-plugin
export var backButton = "blog-post-module--backButton--u1cON";
export var blackLine = "blog-post-module--blackLine--WINcN";
export var content = "blog-post-module--content--d8slE";
export var date = "blog-post-module--date--fETgi";
export var featuredImage = "blog-post-module--featuredImage--KAXLd";
export var frontmatterTitle = "blog-post-module--frontmatterTitle--2g-2w";
export var newsLink = "blog-post-module--newsLink--UE4Az";
export var postWrapper = "blog-post-module--postWrapper--sdeiO";
export var redLine = "blog-post-module--redLine--PCaOl";
export var shareHeader = "blog-post-module--shareHeader--ipEmn";
export var shareIcons = "blog-post-module--shareIcons--v6cQP";
export var wrapper = "blog-post-module--wrapper--EIeUN";