import React from "react"
import { graphql, navigate, Link } from "gatsby"
import * as blogPostStyles from "./blog-post.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import {
  GrFacebook,
  GrInstagram,
  GrTwitter,
  GrShare,
  GrMail,
} from "react-icons/gr"
import { useEffect } from "react"

export default function BlogPost({ data }) {
  const post = data.markdownRemark
  const URL = data.markdownRemark.fields.slug
  const seoImage = data.markdownRemark.frontmatter.featuredImage.publicURL

  // opens all blog links in new tab
  useEffect(() => {
    document
      .querySelectorAll("a")
      .forEach(a => a.setAttribute("target", "_blank"))
  }, [])

  return (
    <div className={blogPostStyles.wrapper}>
      <Seo
        title={post.frontmatter.title}
        image={seoImage}
        description={post.frontmatter.description}
      />
      <div aria-hidden="true" className={blogPostStyles.redLine}></div>
      <div className={blogPostStyles.postWrapper}>
        <h1 className={blogPostStyles.frontmatterTitle}>
          {post.frontmatter.title}
        </h1>
        <GatsbyImage
          className={blogPostStyles.featuredImage}
          style={{ display: `block`, marginLeft: `auto`, marginRight: `auto` }}
          image={post.frontmatter.featuredImage.childImageSharp.gatsbyImageData}
          alt={`${post.frontmatter.title} featured image`}
        />
        <small className={blogPostStyles.date}>{post.frontmatter.date}</small>
        <div
          className={blogPostStyles.content}
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        <p className={blogPostStyles.shareHeader}>Share:</p>
        <ul className={blogPostStyles.shareIcons} role="group">
          <li>
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=https://www.modern-eye.com/news${URL}`}
              title="Facebook Share"
              aria-label="Facebook Share"
            >
              <GrFacebook />
            </a>
          </li>
          <li>
            <a
              href={`
https://twitter.com/share?url=https://www.modern-eye.com/news${URL}`}
              title="Twitter Share"
              aria-label="Twitter Share"
            >
              <GrTwitter />
            </a>
          </li>
          <li>
            <a
              href={`mailto:?subject=Check out this Blog from Modern Eye&amp;body=I wanted you to see the following blog from Modern Eye: https://www.modern-eye.com/news${URL}`}
              title="Email Share"
              aria-label="Email Share"
            >
              <GrMail />
            </a>
          </li>
        </ul>
        {/* BACK BUTTON */}
        <Link
          role="link"
          className={blogPostStyles.newsLink}
          href="/news"
          title="More Posts"
        >
          <span className={blogPostStyles.backButton}>More Posts</span>
        </Link>
      </div>
    </div>
  )
}
export const query = graphql`
  query BlogQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        featuredImage {
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
            id
          }
        }
      }
    }
  }
`
